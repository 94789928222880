import { Article, Email, Launch, LinkedIn } from '@mui/icons-material';
import { AppBar, Box, Container, IconButton, Link, Toolbar, Tooltip, useMediaQuery } from '@mui/material';
import React from 'react';
import theme from '../theme';

type IconData = {
  Icon: React.ElementType;
  link: string;
  tooltip: string;
};

const icons: IconData[] = [
  {
    Icon: LinkedIn,
    link: 'https://www.linkedin.com/in/cory-jbara/',
    tooltip: 'LinkedIn',
  },
  {
    Icon: Email,
    link: 'mailto:cjbara@gmail.com',
    tooltip: 'Email',
  },
  {
    Icon: Article,
    link: '/resume.pdf',
    tooltip: 'My Resume',
  },
];

const navItems = [
  { name: 'About Me', mobileName: 'About', link: 'about' },
  { name: 'My Services', mobileName: 'Services', link: 'services' },
  // { name: 'My Values', mobileName: 'Values', link: '#values' },
  { name: 'Blog', mobileName: 'Blog', link: '/blog', newTab: true, showExternal: true },
];

export const Navbar = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <AppBar position="static" sx={{ backgroundColor: 'transparent' }}>
      <Container maxWidth="lg">
        <Toolbar disableGutters sx={{ justifyContent: 'flex-end' }}>
          <Box sx={{ display: 'flex', gap: 2 }}>
            {navItems.map(({ name, mobileName, link, newTab, showExternal }) => (
              <Link
                key={name}
                color="primary.main"
                href={newTab ? link : `#${link}`}
                target={newTab ? '_blank' : undefined}
                rel={newTab ? 'noopener noreferrer' : undefined}
                underline="none"
                sx={{
                  '&:hover': { opacity: 0.7 },
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 0.5,
                }}
              >
                {isMobile ? mobileName : name}
                {showExternal && <Launch sx={{ fontSize: 14, opacity: 0.6 }} />}
              </Link>
            ))}
          </Box>
          <Box sx={{ display: 'flex', gap: 1, marginLeft: 2 }}>
            {icons.map(({ Icon, link, tooltip }) => (
              <Tooltip title={tooltip} key={tooltip}>
                <IconButton aria-label={tooltip} color="primary" href={link} target="_blank">
                  <Icon />
                </IconButton>
              </Tooltip>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
