import { Box, Button, Grid, Typography } from '@mui/material';
import { SCHEDULE_MEETING_URL } from './ContactMe';

export const Intro: React.FC = () => {
  return (
    <Grid container spacing={4} alignItems="center" justifyContent="center">
      <Grid item xs={12} md={6}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 3 }}>
          <Typography variant="h1" sx={{ mb: 2 }}>
            Cory Jbara
          </Typography>
          <Typography variant="h5" sx={{ mb: 0 }}>
            Your New Strategic Tech Partner
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            I help founders transform their ideas into thriving businesses, one step at a time.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            href={SCHEDULE_MEETING_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            Let's Build Together
          </Button>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{ display: { xs: 'flex', md: 'flex' }, justifyContent: { xs: 'center', md: 'flex-end' } }}
      >
        <Box
          component="img"
          sx={{
            width: '100%',
            height: 'auto',
            borderRadius: '8px',
            display: 'block',
            maxWidth: '400px',
            maxHeight: '400px',
          }}
          alt="Cory Jbara - Software Engineer & Startup Advisor"
          src="/cory.jpeg"
        />
      </Grid>
    </Grid>
  );
};
